<template>
  <v-autocomplete
    v-model="drug"
    :items="drugs"
    :loading="loading"
    :search-input.sync="search"
    :filter="function(item, queryText) {
      return item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || item.brand_names.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    }"
    :label="$tc('drug', 1) | capitalize"
    small-chips
    cache-items
    dense
    multiple
    clearable
    menu-props="offsetY"
    item-text="name.en"
    item-value="id"
    @change="$emit('update:value', drug)"
  >
    <template #prepend-inner>
      <v-icon
        small
        class="mt-1"
        color="grey"
      >
        fal fa-pills
      </v-icon>
    </template>
    <template #item="{ item, attrs, on }">
      <v-list-item
        #default="{ active }"
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-action>
          <v-checkbox :value="active" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title v-text="item.name" />
          <v-list-item-subtitle>{{ item.brand_names | truncate(30) }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>
<script>
  export default {
    props: {
      value: {
        type: Array,
        default: () => [],
      },
      params: {
        type: Object,
        default: () => ({}),
      },
    },
    data () {
      return {
        loading: false,
        search: null,
        drug: this.value,
        drugs: [],
      }
    },
    watch: {
      search () {
        this.fetchDrugs()
      },
      value () {
        this.drug = this.value
      },
    },
    created () {
      this.fetchDrugs()
    },
    methods: {
      fetchDrugs () {
        this.loading = true
        const promise = this.axios.get('admin/drugs', {
          params: {
            ...this.params,
            ...{
              count: 20,
              search: this.search,
            },
          },
        })
        return promise.then((response) => {
          this.drugs = response.data.data
        }).catch(error => {
          this.$toast.error(error.response?.data?.message)
        }).finally(() => {
          this.loading = false
        })
      },
    },
  }
</script>
